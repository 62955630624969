





































































































import Vue from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'

export default Vue.extend({
  computed: {
    ...mapState('player', {
      isPlaying: (state: any) => state.isPlaying,
      currentTime: (state: any) => state.currentTime,
      repeatActive: (state: any) => state.repeat,
      shuffleActive: (state: any) => state.shuffle,
      visible: (state: any) => state.queue.length > 0,
    }),
    ...mapGetters('player', [
      'track',
      'progress',
    ]),
  },
  methods: {
    ...mapActions('player', [
      'playPause',
      'next',
      'previous',
      'toggleRepeat',
      'toggleShuffle',
    ]),
    seek(event: any) {
      if (event.target) {
        const width = event.currentTarget.clientWidth
        const value = event.offsetX / width
        return this.$store.dispatch('player/seek', value)
      }
    },
  }
})
