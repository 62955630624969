



















import Vue from 'vue'
import TrackList from '@/library/TrackList.vue'

export default Vue.extend({
  components: {
    TrackList,
  },
  data() {
    return {
      loading: true,
      items: [] as any[],
    }
  },
  created() {
    this.$api.getRandomSongs().then(items => {
      this.loading = false
      this.items = items// .sort((a: any, b:any) => a.created.localeCompare(b.created));
    })
  }
})
