





















import SWBar from './SWBar.vue'
import ErrorBar from './ErrorBar.vue'
import TopNav from './TopNav.vue'
import Sidebar from './Sidebar.vue'
import Player from '@/player/Player.vue'

export default {
  components: {
    SWBar,
    ErrorBar,
    TopNav,
    Sidebar,
    Player,
  },
}
