















import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      items: [],
    }
  },
  created() {
    this.$api.getGenres().then((items) => {
      this.items = items
    })
  },
})
