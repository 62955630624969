










































import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import About from './About.vue'
import SearchForm from '@/search/SearchForm.vue'

export default Vue.extend({
  components: {
    About,
    SearchForm,
  },
  data() {
    return {
      showAboutModal: false
    }
  },
  computed: {
    ...mapState([
      'server',
      'username',
    ]),
  },
  methods: {
    ...mapActions([
      'showMenu',
    ]),
    scan() {
      this.$api.scan()
    },
    logout() {
      this.$auth.logout()
      this.$router.go(0)
    }
  }
})
