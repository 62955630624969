












import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'

export default Vue.extend({
  computed: {
    ...mapState(['swRefresh', 'swMessage'])
  },
  methods: {
    ...mapMutations([
      'clearSwRefresh'
    ]),
  },
})
