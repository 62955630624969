














import Vue from 'vue'
import { mapState, mapActions } from 'vuex'

export default Vue.extend({
  computed: {
    ...mapState([
      'playlists'
    ]),
  },
  methods: {
    ...mapActions({
      deletePlaylist: 'deletePlaylist'
    })
  }
})
