










import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      query: ''
    }
  },
  methods: {
    search(): void {
      this.$router.push({ name: 'search', query: { q: this.query } })
    }
  }
})
