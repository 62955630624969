
















import Vue from 'vue'
import AlbumList from '@/library/album/AlbumList.vue'
import ArtistList from '@/library/artist/ArtistList.vue'
import TrackList from '@/library/TrackList.vue'

export default Vue.extend({
  components: {
    AlbumList,
    ArtistList,
    TrackList,
  },
  props: {
    query: { type: String, required: true }
  },
  data() {
    return {
      result: null as any,
    }
  },
  watch: {
    query: {
      immediate: true,
      handler(value: string) {
        this.$api.search(value).then(result => {
          this.result = result
        })
      }
    }
  },
})
