







































import Vue from 'vue'
import TrackList from '@/library/TrackList.vue'
import { Album } from '@/shared/api'

export default Vue.extend({
  components: {
    TrackList,
  },
  props: {
    id: { type: String, required: true }
  },
  data() {
    return {
      album: null as null | Album,
    }
  },
  async mounted() {
    this.album = await this.$api.getAlbumDetails(this.id)
  },
  methods: {
    play() {
      if (this.album?.tracks) {
        return this.$store.dispatch('player/playTrackList', {
          index: 0,
          tracks: this.album.tracks,
        })
      }
    },
    toggleStar() {
      if (this.album) {
        const value = !this.album.starred
        this.album.starred = value
        return value
          ? this.$api.starAlbum(this.album.id)
          : this.$api.unstarAlbum(this.album.id)
      }
    }
  }
})
