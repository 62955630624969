






















import Vue from 'vue'
import Logo from './Logo.vue'

export default Vue.extend({
  components: {
    Logo,
  },
  props: {
    visible: { type: Boolean, required: true },
  },
  computed: {
    build: () => process.env.VUE_APP_BUILD,
    buildDate: () => process.env.VUE_APP_BUILD_DATE,
    url: () => 'https://github.com/cyrilou242/airsonic-frontend'
  },
})
