






























import Vue from 'vue'

export default Vue.extend({
  props: {
    square: { type: Boolean, default: false },
  }
})
