

















import Vue from 'vue'

export default Vue.extend({
  props: {
    track: { type: Object, required: true },
  },
  data() {
    return {
      starred: this.track.starred,
    }
  },
  methods: {
    toggleStarred() {
      if (this.starred) {
        this.$api.unstar('track', this.track.id)
      } else {
        this.$api.star('track', this.track.id)
      }
      this.starred = !this.starred
    },
    setNextInQueue() {
      return this.$store.dispatch('player/setNextInQueue', this.track)
    },
    addToQueue() {
      return this.$store.dispatch('player/addToQueue', this.track)
    },
  }
})
