



























































































import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import TrackContextMenu from '@/library/TrackContextMenu.vue'

export default Vue.extend({
  components: {
    TrackContextMenu,
  },
  props: {
    tracks: { type: Array, required: true },
    noAlbum: { type: Boolean, default: false },
    noArtist: { type: Boolean, default: false },
    noDuration: { type: Boolean, default: false },
  },
  computed: {
    ...mapState('player', {
      isPlaying: 'isPlaying',
    }),
    ...mapGetters({
      playingTrackId: 'player/trackId',
    }),
  },
  methods: {
    ...mapActions({
      playPause: 'player/playPause',
    }),
    play(index: number) {
      if ((this.tracks as any)[index].id === this.playingTrackId) {
        return this.$store.dispatch('player/playPause')
      }
      return this.$store.dispatch('player/playTrackList', {
        index,
        tracks: this.tracks,
      })
    },
    dragstart(id: string, event: any) {
      event.dataTransfer.setData('id', id)
    },
  }
})
