











import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import TrackList from '@/library/TrackList.vue'

export default Vue.extend({
  components: {
    TrackList,
  },
  computed: {
    ...mapState('player', {
      tracks: (state: any) => state.queue,
    })
  },
  methods: {
    ...mapMutations('player', {
      remove: 'removeFromQueue',
    }),
  }
})
