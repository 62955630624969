
















import Vue from 'vue'
import AlbumList from '@/library/album/AlbumList.vue'
import ArtistList from '@/library/artist/ArtistList.vue'
import TrackList from '@/library/TrackList.vue'

export default Vue.extend({
  components: {
    AlbumList,
    ArtistList,
    TrackList,
  },
  data() {
    return {
      result: null as any,
    }
  },
  created() {
    this.$api.getStarred().then(result => {
      this.result = result
    })
  }
})
