



















































import Vue from 'vue'
import Nav from './Nav.vue'
import { mapState, mapActions } from 'vuex'

export default Vue.extend({
  components: {
    Nav,
  },
  computed: {
    ...mapState(['showMenu'])
  },
  methods: {
    ...mapActions(['hideMenu']),
  },
})
