



























import Vue from 'vue'
import AlbumList from '@/library/album/AlbumList.vue'
import TrackList from '@/library/TrackList.vue'

export default Vue.extend({
  components: {
    AlbumList,
    TrackList,
  },
  props: {
    id: { type: String, required: true },
    section: { type: String, default: '' },
  },
  data() {
    return {
      albums: null as null | any[],
      tracks: null as null | any[],
    }
  },
  created() {
    this.$api.getAlbumsByGenre(this.id).then(result => {
      this.albums = result
    })
    this.$api.getTracksByGenre(this.id).then(result => {
      this.tracks = result
    })
  }
})
