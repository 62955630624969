




























import Vue from 'vue'
import { config } from '@/shared/config'

export default Vue.extend({
  props: {
    returnTo: { type: String, required: true },
  },
  data() {
    return {
      server: '',
      username: '',
      password: '',
      rememberLogin: true,
      busy: false,
      error: null,
      showModal: false,
    }
  },
  computed: {
    valid(): false | null {
      return this.error == null ? null : false
    },
    config: () => config
  },
  async created() {
    this.server = await this.$auth.server
    this.username = await this.$auth.username
    const success = await this.$auth.autoLogin()
    if (success) {
      this.$store.commit('setLoginSuccess', {
        username: this.username,
        server: this.server,
      })
      this.$router.replace(this.returnTo)
    } else {
      this.showModal = true
    }
  },
  methods: {
    login() {
      this.error = null
      this.busy = true
      this.$auth.loginWithPassword(this.server, this.username, this.password, this.rememberLogin)
        .then(() => {
          this.$store.commit('setLoginSuccess', {
            username: this.username,
            server: this.server,
          })
          this.$router.replace(this.returnTo)
        })
        .catch(err => {
          this.error = err
        })
        .finally(() => {
          this.busy = false
        })
    }
  }
})
