

































import Vue from 'vue'
import TrackContextMenu from '@/library/TrackContextMenu.vue'
import { RadioStation } from '@/shared/api'
import { mapGetters } from 'vuex'

export default Vue.extend({
  components: {
    TrackContextMenu,
  },
  data() {
    return {
      items: [] as RadioStation[],
    }
  },
  computed: {
    ...mapGetters({
      playingTrackId: 'player/trackId',
    }),
  },
  async created() {
    this.items = await this.$api.getRadioStations()
  },
  methods: {
    play(index: number) {
      if (this.items[index].id === this.playingTrackId) {
        return this.$store.dispatch('player/playPause')
      }
      return this.$store.dispatch('player/playTrackList', {
        index,
        tracks: this.items
      })
    },
  }
})
